import { graphql, navigate } from 'gatsby'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Gif from '../../specific/pages/404.gif'
import Layout from '../components/Layout'
import { SeoData } from '../types/Seo'
import route from '../utils/route'
import styles from './index.module.scss'

interface Props {
  data: {
    seoData: SeoData
  }
}
const NotFoundPage = ({ data }: Props) => (
  <Layout selector={false} seoData={data.seoData}>
    <div className={styles.error_page}>
      <div className={styles.error_page_title}>
        <div className={styles.error_page_title_1}>
          <p className={styles.error_page_title_yellow}>
            <FormattedMessage id="error_page.ups" />{' '}
          </p>
          <p className={styles.error_page_title_black}>
            <FormattedMessage id="error_page.title-1" />
          </p>
        </div>
        <p className={styles.error_page_title_black}>
          <FormattedMessage id="error_page.title-2" />
        </p>
      </div>
      <img src={Gif} alt="404 image"></img>
      <div className={styles.error_page_text_cont}>
        <p className={styles.error_page_text}>
          <FormattedMessage id="error_page.text-1" />
        </p>
        <p className={styles.error_page_text}>
          <FormattedMessage id="error_page.text-2" />
        </p>
        <button
          className={styles.error_button}
          onClick={() => navigate(route('index'))}>
          <FormattedMessage id="bread_crumb.inicio" />
        </button>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query errorPageNotFoundQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
